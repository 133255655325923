<template>
  <v-container v-if="pageLoading" class="bg-color-theme px-3">
    <v-skeleton-loader type="list-item-two-line, card"></v-skeleton-loader>
  </v-container>

  <v-container v-else class="bg-color-theme px-3">
    <v-app-bar dense flat rounded color="primary" dark class="">
      <v-btn icon @click="targetPrev()">
        <v-icon>mdi-menu-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title class="font-18px"
        >{{ currentDate.format("M") }}月の月報</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn icon @click="targetForward()">
        <v-icon>mdi-menu-right</v-icon>
      </v-btn>
    </v-app-bar>

    <div v-for="(message, i) in warnings" :key="i">
      <div
        class="elevation-3 rounded monthly-warning text-center py-5 my-4"
        v-if="message.show"
      >
        <div class="d-flex align-center justify-center">
          <v-icon size="19" class="mr-2">$warning</v-icon>
          <span>{{ message.heading }}</span>
        </div>
        <p
          class="mb-0 font-14px mt-1"
          @click="
            $router.push({
              name: message.linkTo,
              query: { ...message.query }
            })
          "
        >
          {{ message.instruction }}
        </p>
      </div>
    </div>
    <v-card color="transparent" class="position-container" flat>
      <MonthlyCard number="1" title="サマリー" ref="card1">
        <div class="div-blue px-4 py-3">
          <table class="border-bottom custom-table">
            <tr :key="i" v-for="(item, i) in summary" class="mb-0">
              <td class="primary--text font-14px pr-3 pl-2 column-1">
                {{ item.label }}
              </td>
              <td class="fw-700 column-2">
                {{ item.price }}
              </td>
              <td class="text-87 font-12px pl-1 column-3">
                <span class="price-text" :class="item.classList">{{
                  item.secondary
                }}</span>
                <span class="">{{ item.achievementRate }}</span>
                <v-icon
                  v-if="i === 5 || i === 6"
                  size="15"
                  role="button"
                  @click="helpModal(i)"
                  class="ml-1"
                  >$infoIconGrey</v-icon
                >
              </td>
            </tr>
          </table>
        </div>

        <h1 class="font-16px mt-8 text-666 mb-5">売上の推移</h1>

        <MonthlySales
          :monthlySalesData="
            barGraphMonthlySales ? barGraphMonthlySales.daily_reports : []
          "
          :daysInMonth="
            barGraphMonthlySales ? barGraphMonthlySales.business_days_count : 0
          "
          :avgSalesAmount="
            barGraphMonthlySales ? barGraphMonthlySales.total_sale_avg : 0
          "
        ></MonthlySales>
      </MonthlyCard>

      <MonthlyCard number="2" title="売上 " ref="card2">
        <v-tabs
          v-model="tab"
          height="30px"
          class="font-12px meal-tabs mb-5"
          hide-slider
          color="#9E9E9E"
          background-color="transparent"
          grow
        >
          <v-tab class="font-12px" active-class="meal-tab-blue">
            <v-icon size="13">$sunAlt</v-icon>
            <span>
              一日
            </span>
          </v-tab>
          <v-tab class="font-12px" active-class="meal-tab-yellow">
            <v-icon size="13">$sunAlt</v-icon>
            <span>
              ランチ
            </span>
          </v-tab>
          <v-tab class="font-12px" active-class="meal-tab-orange">
            <v-icon size="13">$moon</v-icon>
            <span>
              ディナー
            </span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="transparent">
          <v-tab-item>
            <Earnings
              colorBg="#F4F8FC"
              colorLine="#BDBDBD"
              id="pieChart1"
              colorSvg="#6E77F0"
              :monthly-report="reports.all_day_sales"
              :current-date="currentDate"
            ></Earnings>
          </v-tab-item>
          <v-tab-item>
            <Earnings
              colorBg="#FFFCE9"
              colorLine="#BDBDBD"
              id="pieChart2"
              colorSvg="#e5d02d"
              :monthly-report="reports.lunch_sales"
              :current-date="currentDate"
            ></Earnings>
          </v-tab-item>
          <v-tab-item>
            <Earnings
              colorBg="#FFF7F2"
              colorLine="#DED7D3"
              id="pieChart3"
              colorSvg="#e98b50"
              :monthly-report="reports.dinner_sales"
              :current-date="currentDate"
            ></Earnings>
          </v-tab-item>
        </v-tabs-items>
      </MonthlyCard>

      <MonthlyCard number="3" title="コスト" ref="card3">
        <HeaderData :data="headerData" colorBg="#FFF5FF"></HeaderData>
        <div class="d-flex justify-center align-center mt-10 mb-6 flex-column">
          <DoughnutChart :showLabels="true" :labels="saleCostCompositionRatio">
            <template v-slot:title>
              <p class="text-888 font-12px mb-3">
                {{ currentDate.format("M") }}月の対売上コスト構成比
              </p>
              <p v-if="reports" class="text-888 font-12px mb-9">
                {{
                  `${reports.date.month}月${reports.date.from}日～${reports.date.month}月${reports.date.to}日`
                }}
              </p>
            </template>
            <template v-slot:inner-text>
              <div class="font-12px font-weight-bold">累計売上</div>
              <div class="font-weight-bold font-14px">
                {{ (reports ? reports.earnings : 0) | formatMoney }}
              </div>
            </template>
            <template v-slot:result>
              <p class="mt-5">
                <span>累計コスト </span>
                <span class="fw-700">
                  {{ (reports ? reports.costs : 0) | formatMoney }}
                </span>
              </p>
            </template>
          </DoughnutChart>

          <DoughnutChart :showLabels="true" :labels="labourCost">
            <template v-slot:title>
              <p class="text-888 font-12px my-6">
                {{ currentDate.format("M") }}月 累計人件費の内訳
              </p>
            </template>
            <template v-slot:inner-text>
              <div class="font-12px font-weight-bold">累計人件費</div>
              <div class="font-weight-bold font-14px">
                <span>￥</span>
                {{ (reports ? reports.cost.labor_cost : 0) | formatNumber }}
              </div>
            </template>
          </DoughnutChart>
          <DoughnutChart :showLabels="true" :labels="majorCustomers">
            <template v-slot:title>
              <p class="text-888 font-12px my-6">
                {{ currentDate.format("M") }}月 主要取引先（5社）構成比
              </p>
            </template>
            <template v-slot:inner-text>
              <div class="font-12px font-weight-bold">総取引高</div>
              <div class="font-weight-bold font-14px">
                <span>￥</span> {{ partners.cost_total | formatNumber }}
              </div>
            </template>
          </DoughnutChart>
        </div>
      </MonthlyCard>

      <MonthlyCard number="4" title="食材原価率" ref="card4">
        <div
          v-if="reports && reports.cost"
          class="rounded color-bg d-flex flex-wrap text-center card4-head"
        >
          <div>
            <p class="mb-0">{{ currentDate.format("M") }}月の食材原価率</p>
            <span>{{ reports.cost.total_inventory_percent }}%</span>
          </div>
          <div>
            <p class="mb-0">{{ currentDate.format("M") }}月のフード原価率</p>
            <span>{{ reports.cost.food_inventory_percent }}%</span>
          </div>
          <div>
            <p class="mb-0">{{ currentDate.format("M") }}月のドリンク原価率</p>
            <span>{{ reports.cost.drink_inventory_percent }}%</span>
          </div>
        </div>
        <p
          class="mb-1 mt-3 font-12px text-red"
          v-if="isCurrentInventoryExist == 'not-exist'"
        >
          ※ 今月の棚卸額が未設定です。
        </p>

        <v-expansion-panels flat accordion>
          <v-expansion-panel>
            <v-expansion-panel-header
              class="px-0 font-12px text-right justify-end report-header-color"
            >
              <span>
                詳細表示
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="expansion-content">
              <div class="rounded card4-expansion px-3 py-5">
                <table class="custom-table">
                  <tr v-for="(row, i) in tableData" :key="i">
                    <td class="text-777 py-1 px-5">{{ row.label }}</td>
                    <td class="fw-700 py-1 px-5">{{ row.value }}</td>
                    <td
                      class="fw-700 py-1 px-5"
                      v-if="row.is_inventory_exist == 'not-exist'"
                    >
                      <router-link :to="{ name: 'InventoryManagementList' }">
                        <v-icon small>$editGray</v-icon>
                      </router-link>
                    </td>
                  </tr>
                </table>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </MonthlyCard>
    </v-card>
    <div class="d-flex justify-center">
      <v-card flat color="transparent" class="position-button mx-auto">
        <div>
          <v-icon
            @click="$router.push({ name: 'BusinessReportBudget' })"
            size="44"
            >$cancel</v-icon
          >
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import Earnings from "@/components/layout/monthly-report/Earnings";
import DoughnutChart from "@/components/layout/budget/DoughnutChart";
import HeaderData from "@/components/layout/monthly-report/HeaderData";
import MonthlyCard from "@/components/layout/monthly-report/MonthlyCard";
import MonthlySales from "@/components/layout/budget/MonthlySales";
import dayjs from "@/plugins/dayjs";

export default {
  name: "report",
  components: {
    Earnings,
    HeaderData,
    DoughnutChart,
    MonthlyCard,
    MonthlySales
  },
  data() {
    return {
      pageLoading: false,
      tab: null,
      currentDate: dayjs(this.$route.params.date),
      previousMonthDate: dayjs(this.$route.params.date).format("YYYY-MM"),
      nextMonthDate: dayjs(this.$route.params.date).format("YYYY-MM"),
      cards: ["card1", "card2", "card3", "card4"],
      warnings: {
        monthlyFixedCost: {
          heading: "月コストが未設定です。",
          instruction: "月コストを設定する",
          linkTo: "costInput",
          query: {},
          show: true
        },
        goals: {
          heading: "売上目標が未設定です。",
          instruction: "売上目標を設定する",
          linkTo: "GoalSetting",
          query: {},
          show: true
        },
        inventory: {
          heading: "前月棚卸金額が未設定です。",
          instruction: "前月棚卸金額を設定する",
          linkTo: "InventoryManagementEdit",
          query: {},
          show: true
        }
      },
      summary: []
    };
  },
  computed: {
    reports() {
      return this.getMonthlyReport?.reports;
    },
    getMonthlyReportGoal() {
      return this.$store.getters.getMonthlyReport.goal;
    },
    getMonthlyReportInventory() {
      return this.$store.getters.getMonthlyReport.prev_month_inventory;
    },
    getMonthlyReportFixedCost() {
      return this.$store.getters.getMonthlyReport.fixed_cost;
    },
    isCurrentInventoryExist() {
      return this.reports?.cost?.inventory?.current?.total == undefined ||
        this.reports?.cost?.inventory?.current?.total <= 0
        ? "not-exist"
        : "";
    },
    tableData() {
      return [
        {
          label: this.currentDate.add("-1", "month").format("M") + "月の棚卸",
          value: this.priceFormat(
            this.reports?.cost?.inventory?.previous?.total ?? "￥--"
          )
        },
        {
          label: "フード",
          value: this.priceFormat(
            this.reports?.cost?.inventory?.previous?.food ?? "￥--"
          )
        },
        {
          label: "ドリンク",
          value: this.priceFormat(
            this.reports?.cost?.inventory?.previous?.drink ?? "￥--"
          )
        },
        {
          label: this.currentDate.format("M") + "月の仕入",
          value: this.priceFormat(
            this.reports?.cost?.inventory?.cost?.total ?? "￥--"
          )
        },
        {
          label: "フード",
          value: this.priceFormat(
            this.reports?.cost?.inventory?.cost?.food ?? "￥--"
          )
        },
        {
          label: "ドリンク",
          value: this.priceFormat(
            this.reports?.cost?.inventory?.cost?.drink ?? "￥--"
          )
        },
        {
          label: this.currentDate.format("M") + "月の食材原価",
          value: this.priceFormat(
            this.reports?.cost?.inventory?.ingredients?.total ?? "￥--"
          )
        },
        {
          label: "フード",
          value: this.priceFormat(
            this.reports?.cost?.inventory?.ingredients?.food ?? "￥--"
          )
        },
        {
          label: "ドリンク",
          value: this.priceFormat(
            this.reports?.cost?.inventory?.ingredients?.drink ?? "￥--"
          )
        },
        {
          label: this.currentDate.format("M") + "月の棚卸",
          value: this.priceFormat(
            this.reports?.cost?.inventory?.current?.total ?? "￥--"
          ),
          is_inventory_exist: this.isCurrentInventoryExist
        },
        {
          label: "フード",
          value: this.priceFormat(
            this.reports?.cost?.inventory?.current?.food ?? "￥--"
          )
        },
        {
          label: "ドリンク",
          value: this.priceFormat(
            this.reports?.cost?.inventory?.current?.drink ?? "￥--"
          )
        },
        {
          label: this.currentDate.format("M") + "月の売上",
          value: this.priceFormat(
            this.reports?.cost?.inventory?.sales?.total ?? "￥--"
          )
        },
        {
          label: "フード等",
          value: this.priceFormat(
            this.reports?.cost?.inventory?.sales?.food ?? "￥--"
          )
        },
        {
          label: "ドリンク",
          value: this.priceFormat(
            this.reports?.cost?.inventory?.sales?.drink ?? "￥--"
          )
        }
      ];
    },
    getShop() {
      return this.$store.getters.getFirstShopObject;
    },
    getMonthlyReport() {
      return this.$store.getters.getMonthlyReport;
    },
    barGraphMonthlySales() {
      return this.getMonthlyReport?.bar_graph_report;
    },
    partners() {
      return this.reports?.partners;
    },
    majorCustomers() {
      let colors = [
        "#32CFA0",
        "#478ABF",
        "#64A46B",
        "#9FDFA5",
        "#FFFF87",
        "#EFF2F7"
      ];
      let partners = [];

      if (this.partners && this.partners.partners.length > 0) {
        this.partners.partners.forEach((item, index) => {
          partners.push({
            color: colors[index] ?? "",
            percentage: item?.percent ?? 0,
            short_text: item?.display_name
              ? item.display_name.substring(0, 2)
              : "-",
            text: item?.display_name ?? "-",
            price: item?.total_purchases ?? 0
          });
        });

        partners.push({
          color: "#EFF2F7",
          percentage: this.partners?.partners?.other?.percent ?? 0,
          text: "その他",
          price: this.partners?.partners?.other?.cost ?? 0
        });
      }

      return partners;
    },
    labourCost() {
      return [
        {
          color: "#FFBF1A",
          percentage: this.reports?.cost?.percentage?.rent_cost ?? 0,
          text: "正社員給料 ",
          price: this.reports?.cost?.rent_cost ?? 0
        },
        {
          color: "#FFE7AA",
          percentage: 100 - this.reports?.cost?.percentage?.rent_cost,
          text: "アルバイト給料",
          price: this.reports?.cost?.payments ?? 0
        }
      ];
    },
    saleCostCompositionRatio() {
      let profitPercent = this.reports?.cost?.percentage?.profit;
      return [
        {
          color: "#FFBF1A",
          percentage: this.reports?.cost?.percentage?.labor_cost ?? 0,
          text: "人件費",
          price: this.reports?.cost?.labor_cost ?? 0
        },
        {
          color: "#14E233",
          percentage: this.reports?.cost?.percentage?.foodstuff_cost ?? 0,
          text: "食材費 ",
          price: this.reports?.cost?.foodstuff_cost ?? 0
        },
        {
          color: "#A0A4DC",
          percentage: this.reports?.cost?.percentage?.platform_cost ?? 0,
          text: "PF手数料",
          price: this.reports?.cost?.platform_cost ?? 0
        },
        {
          color: "#DCC8B1",
          percentage: this.reports?.cost?.percentage?.rent_cost ?? 0,
          text: "家賃",
          price: this.reports?.cost?.rent_cost ?? 0
        },
        {
          color: "#F4F4F4",
          percentage: this.reports?.cost?.percentage?.other_cost ?? 0,
          text: "その他",
          price: this.reports?.cost?.other_cost ?? 0
        },
        {
          color: "#FCFCBC",
          percentage: profitPercent < 0 ? -1 : profitPercent,
          text: "損益",
          price: this.reports?.profit ?? 0
        }
      ];
    },
    headerData() {
      return [
        {
          label: "累計コスト",
          value: this.priceFormat(this.reports?.costs)
        },
        {
          label: "人件費（L）",
          value: this.priceFormat(this.reports?.cost?.labor_cost),
          secondary:
            "（" + (this.reports?.cost?.percentage?.labor_cost ?? 0) + "%）",
          list: [
            {
              label: "正社員",
              value: this.priceFormat(
                this.reports?.cost?.regular_employee?.cost
              ),
              secondary:
                "（" +
                (this.reports?.cost?.regular_employee?.percent ?? 0) +
                "%）"
            },
            {
              label: "アルバイト",
              value: this.priceFormat(this.reports?.cost?.part_time_job?.cost),
              secondary:
                "（" + (this.reports?.cost?.part_time_job?.percent ?? 0) + "%）"
            }
          ]
        },
        {
          label: "食材費（F）",
          value: this.priceFormat(this.reports?.cost?.foodstuff_cost),
          secondary:
            "（" +
            (this.reports?.cost?.percentage?.foodstuff_cost ?? 0) +
            "%）",
          list: [
            {
              label: "フード",
              value: this.priceFormat(this.reports?.cost?.food_cost?.cost),
              secondary:
                "（" + (this.reports?.cost?.food_cost?.percent ?? 0) + "%）"
            },
            {
              label: "ドリンク",
              value: this.priceFormat(this.reports?.cost?.drink_cost?.cost),
              secondary:
                "（" + (this.reports?.cost?.drink_cost?.percent ?? 0) + "%）"
            }
          ]
        },
        {
          label: "家賃（R）",
          value: this.priceFormat(this.reports?.cost?.rent_cost),
          secondary:
            "（" + (this.reports?.cost?.percentage?.rent_cost ?? 0) + "%）"
        },
        {
          label: "プラットフォーム手数料（P）",
          value: this.priceFormat(this.reports?.cost?.platform_cost),
          secondary:
            "（" + (this.reports?.cost?.percentage?.platform_cost ?? 0) + "%）"
        },
        {
          label: "その他",
          value: this.priceFormat(this.reports?.cost?.other_cost),
          secondary:
            "（" + (this.reports?.cost?.percentage?.other_cost ?? 0) + "%）"
        }
      ];
    }
  },
  async mounted() {
    this.pageLoading = true;
    this.current = dayjs(this.$route.params.date).format("YYYY-M");
    await this.fetchMonthlyReport();
    this.updateWarnings();
  },
  methods: {
    updateWarnings() {
      if (this.getMonthlyReportInventory) {
        this.warnings.inventory.show = false;
      } else {
        this.warnings.inventory.show = true;
        this.warnings.inventory.query.year_month = dayjs(this.current)
          .subtract(1, "month")
          .format("YYYY-MM");
      }
      if (this.getMonthlyReportFixedCost) {
        this.warnings.monthlyFixedCost.show = false;
      } else {
        this.warnings.monthlyFixedCost.show = true;
        this.warnings.monthlyFixedCost.query.year_month = dayjs(
          this.current
        ).format("YYYY-MM");
      }
      if (
        this.getMonthlyReportGoal == undefined ||
        !this.getMonthlyReportGoal
      ) {
        this.pageLoading = false;
        return;
      }
      let months = Object.keys(this.getMonthlyReportGoal);
      if (months.length > 0 && this.getMonthlyReportGoal[months[0]]) {
        this.warnings.goals.show = false;
      } else {
        this.warnings.goals.show = true;
      }
      this.pageLoading = false;
    },
    targetForward() {
      this.previousMonthDate = dayjs(this.nextMonthDate)
        .add("1", "month")
        .format("YYYY-MM-DD"); // First increment prev then next month
      this.nextMonthDate = dayjs(this.nextMonthDate)
        .add("1", "month")
        .format("YYYY-MM-DD");

      this.currentDate = dayjs(this.nextMonthDate);
      this.$router.push({
        name: "monthlyReport",
        params: { date: this.currentDate.format("YYYY-MM") }
      });
    },
    targetPrev() {
      this.nextMonthDate = dayjs(this.previousMonthDate)
        .add("-1", "month")
        .format("YYYY-MM-DD"); // First increment next then prev month
      this.previousMonthDate = dayjs(this.previousMonthDate)
        .add("-1", "month")
        .format("YYYY-MM-DD");

      this.currentDate = dayjs(this.previousMonthDate);
      this.$router.push({
        name: "monthlyReport",
        params: { date: this.currentDate.format("YYYY-MM") }
      });
    },
    helpModal(i) {
      if (i === 5)
        this.$store.commit("showModal", { component: "IngredientCostRate" });
      if (i === 6)
        this.$store.commit("showModal", { component: "LaborCostRate" });
    },
    async fetchMonthlyReport() {
      await this.$store
        .dispatch("GET_MONTHLY_REPORT", {
          shop_id: this.getShop.id,
          date: this.currentDate.format("YYYY-MM"),
          param: {
            by_month: 1
          }
        })
        .then(() => {
          this.fillPageData();
        });
    },
    fillPageData() {
      this.summary = [
        {
          label: "売上",
          price: this.priceFormat(this.reports?.sales ?? 0),
          secondary: "達成率 " + (this.reports?.achievement_rate ?? 0) + "%",
          classList: "no-position"
        },
        {
          label: "( 現金売上 )",
          price: this.priceFormat(this.reports?.cash_sales ?? 0)
        },
        {
          label: "目標売上",
          price: this.priceFormat(this.reports?.goal ?? 0)
        },
        {
          label: "コスト",
          price: this.priceFormat(this.reports?.costs ?? 0)
        },
        {
          label: "損益",
          price: this.priceFormat(this.reports?.profit_and_loss ?? 0)
        },
        {
          label: "食材原価率",
          // price: (this.reports?.cost?.percentage?.foodstuff_cost ?? 0) + "%",
          // secondary: this.priceFormat(this.reports?.cost?.foodstuff_cost)
          price: (this.reports?.cost?.total_inventory_percent ?? 0) + "%",
          secondary: this.priceFormat(
            this.reports?.cost?.inventory?.ingredients?.total ?? "￥--"
          )
        },
        {
          label: "人件費率",
          price: (this.reports?.cost?.percentage?.labor_cost ?? 0) + "%",
          secondary: this.priceFormat(this.reports?.cost?.labor_cost)
        },
        {
          label: "客単価",
          price: this.priceFormat(
            this.reports?.cost?.average_sales_per_customer ?? 0
          )
        }
      ];
    }
  }
};
</script>

<style lang="scss" src="./style.scss"></style>
